$(function () {
	APP.init();
	lozadInit();
	tocInit();
	fancyboxInit();
	countUpInit();

	accordionCollapse();
	nextSection();
});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backdrop = $(".backdrop"),
	toggleBackdrop = $(".toggle-backdrop"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	mobileWrap = $(".mobile-wrap"),
	buttonSearch = $("header .button-search"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			$(this).removeClass('open');
			toggleBackdrop.removeClass('open');
			body.removeClass('overflow-hidden');
		})
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		let mainMenu = $("header .navbar-nav").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: "header .header-right",
			desktopMethod: "prependTo",
			breakpoint: 1279.98,
		});
		let searchBox = $(".search-wrap .searchbox").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "insertBefore",
			desktopWrapper: ".search-wrap",
			desktopMethod: "prependTo",
			breakpoint: 1279.98,
		});
		let buttonContact = $("header .button-contact").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "appendTo",
			desktopWrapper: "header .button-group",
			desktopMethod: "appendTo",
			breakpoint: 767.98,
		});
	},
	toggleDropdown: () => {
		$('.dropdown .dropdown-toggle').on('click', function(){
			$(this).parents('.dropdown').toggleClass('active').find('.dropdown-menu').slideToggle();
		})
	},
	toggleElm: (button, elmToggle) => {
		button.on("click", function () {
			$(this).toggleClass("open");
			elmToggle.toggleClass('open');
			backdrop.toggleClass('open');
			body.toggleClass('overflow-hidden');
			if (button == buttonSearch){
				$('.searchbox input').trigger('focus')
			}
		});
	},
	toggleMenu: () => {
		$(".main-menu .icon-toggle").on('click', function(){
			$(this).toggleClass('active').parent().find('.sub-menu').slideToggle();
			$(this).parent().siblings().find('.sub-menu').slideUp()
			$(this).parent().siblings().find('.icon-toggle').removeClass('active')
		})
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.mapping();
		APP.toggleDropdown();
		APP.toggleMenu();
		APP.toggleElm(buttonMenu, mobileWrap)
		APP.toggleElm(buttonSearch, searchWrap)
	}
}

const homeBannerSlider = new Swiper(".home-banner .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-banner .button-next",
		prevEl: ".home-banner .button-prev",
	},
	pagination: {
		el: ".home-banner .swiper-pagination",
		clickable: true,
	},
});

const home4RoomSlider = new Swiper(".home-4-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 32,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-4-swiper .button-next",
		prevEl: ".home-4-swiper .button-prev",
	},
	pagination: {
		el: ".home-4-swiper .swiper-pagination",
		clickable: true,
	},
});

const home9Slider = new Swiper(".home-9-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 32,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-9-swiper .button-next",
		prevEl: ".home-9-swiper .button-prev",
	},
	pagination: {
		el: ".home-9-swiper .swiper-pagination",
		clickable: true,
	},
});

const about2Slider = new Swiper(".about-2-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".about-2-swiper .button-next",
		prevEl: ".about-2-swiper .button-prev",
	},
	pagination: {
		el: ".about-2-swiper .swiper-pagination",
		clickable: true,
	},
});

const about3Slider = new Swiper(".about-3-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 30,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".about-3-swiper .button-next",
		prevEl: ".about-3-swiper .button-prev",
	},
	pagination: {
		el: ".about-3-swiper .swiper-pagination",
		clickable: true,
	},
	scrollbar: {
		el: ".about-3-swiper .swiper-scrollbar",
		// hide: true,
	},
	breakpoints: {
		1280: {
			spaceBetween: 90,
			loop: false,
			autoplay: false,
		},
	},
});

const about4Slider = new Swiper(".about-4-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 2,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".about-4-swiper .button-next",
		prevEl: ".about-4-swiper .button-prev",
	},
	pagination: {
		el: ".about-4-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			spaceBetween: 24,
			slidesPerView: 3,
		},
		1024: {
			spaceBetween: 24,
			slidesPerView: 4,
		},
		1280: {
			spaceBetween: 32,
			slidesPerView: 4,
		},
		1366: {
			spaceBetween: 32,
			slidesPerView: 5,
		},
		1440: {
			spaceBetween: 32,
			slidesPerView: 6,
		},
	},
});

const roomOtherSlider = new Swiper(".room-other-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 1,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".room-other-swiper .button-next",
		prevEl: ".room-other-swiper .button-prev",
	},
	pagination: {
		el: ".room-other-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			spaceBetween: 24,
			slidesPerView: 2,
		},
		1024: {
			spaceBetween: 24,
			slidesPerView: 2,
		},
		1280: {
			spaceBetween: 32,
			slidesPerView: 3,
		},
	},
});


const promotionOtherSlider = new Swiper(".promotion-other-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 1,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".promotion-other-swiper .button-next",
		prevEl: ".promotion-other-swiper .button-prev",
	},
	pagination: {
		el: ".promotion-other-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			spaceBetween: 24,
			slidesPerView: 2,
		},
		1024: {
			spaceBetween: 24,
			slidesPerView: 2,
		},
		1280: {
			spaceBetween: 32,
			slidesPerView: 3,
		},
	},
});

const experience2Slider = new Swiper(".experience-2-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 1,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".experience-2-swiper .button-next",
		prevEl: ".experience-2-swiper .button-prev",
	},
	pagination: {
		el: ".experience-2-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			spaceBetween: 24,
			slidesPerView: 2,
		},
		1024: {
			spaceBetween: 24,
			slidesPerView: 2,
		},
		1280: {
			spaceBetween: 32,
			slidesPerView: 3,
		},
	},
});

const experience3Slider = new Swiper(".experience-3-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 1,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".experience-3-swiper .button-next",
		prevEl: ".experience-3-swiper .button-prev",
	},
	pagination: {
		el: ".experience-3-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			spaceBetween: 24,
			slidesPerView: 2,
			loop: false,
			autoplay: false,
			grid:{
				rows: 2,
				fill: 'row'
			}
		},
		1024: {
			spaceBetween: 24,
			slidesPerView: 2,
			loop: false,
			autoplay: false,
			grid: {
				rows: 2,
				fill: 'row'
			}
		},
		1280: {
			spaceBetween: 32,
			slidesPerView: 4,
			loop: false,
			autoplay: false,
			grid: {
				rows: 2,
				fill: 'row'
			}
		},
	},
});

const experience4Slider = new Swiper(".experience-4-swiper .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 1,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".experience-4-swiper .button-next",
		prevEl: ".experience-4-swiper .button-prev",
	},
	pagination: {
		el: ".experience-4-swiper .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			spaceBetween: 24,
			slidesPerView: 2,
		},
		1024: {
			spaceBetween: 24,
			slidesPerView: 2,
		},
		1280: {
			spaceBetween: 32,
			slidesPerView: 2,
		},
	},
});

const videoThumbSlider = new Swiper(".video-thumb-swiper .swiper", {
	loop: true,
	speed: 1000,
	spaceBetween: 20,
	slidesPerView: 1,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	watchSlidesProgress: true,
	breakpoints: {
		768: {
			slidesPerView: 3,
		},
		1024: {
			slidesPerView: 4,
		},
		1280: {
			slidesPerView: 4,
			direction: "vertical",
		},
	},
});

const videoTopSlider = new Swiper(".video-top-swiper .swiper", {
	loop: true,
	speed: 1000,
	spaceBetween: 20,
	slidesPerView: 1,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".video-swiper .button-next",
		prevEl: ".video-swiper .button-prev",
	},
	pagination: {
		el: ".video-swiper .swiper-pagination",
		clickable: true,
	},
	thumbs: {
		swiper: videoThumbSlider,
	},
});

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function tocInit() {
	$(".full-content :header").each(function (index) {
		var headingText = $(this).text().trim();
		$(this).attr("id", removeVietnameseDiacritics(headingText));
	});

	tocOptions = {
		// Where to grab the headings to build the table of contents.
		contentSelector: ".full-content",
		// Which headings to grab inside of the contentSelector element.
		headingSelector: "h2, h3, h4",
		// For headings inside relative or absolute positioned containers within content.
		hasInnerContainers: true,
		headingsOffset: 150,
		scrollSmoothOffset: -150,
		scrollEndCallback: function (e) {
		},
		positionFixedSelector: ".toc-pin-section",
	};
	tocbot.init({
		// Where to render the table of contents.
		tocSelector: ".js-toc",
		...tocOptions,
	});
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function countUpInit() {
	$('.count-item').each(function (index) {
		$(this).attr('id', `countUp-${index}`)
		const endVal = $(this).data('count');

		const options = {
			separator: '.',
			enableScrollSpy: true,
			scrollSpyDelay: 1000,
			scrollSpyOnce: true,
		};

		const countUp = new CountUp(`countUp-${index}`, endVal, options);

		if (!countUp.error) {
			countUp.start();
		} else {
			console.error(countUp.error);
		}

		countUp.handleScroll();
	});
}

function accordionCollapse() {
	$('.accordion-item').on('click', function () {
		$(this)
			.toggleClass('active')
			.siblings().removeClass('active')
		$(this)
			.find('.accordion-content').slideToggle()
			.parents('.accordion-item')
			.siblings().find('.accordion-content').slideUp()
	});
}

function nextSection(){
	$('#next-button').on('click', function () {
		// Get the current section
		var currentSection = $(this).closest('section');

		// Find the next section
		var nextSection = currentSection.next('section');

		// Check if there is a next section
		if (nextSection.length > 0) {
			// Scroll to the top of the next section
			$('html, body').animate({
				scrollTop: nextSection.offset().top
			}, 'slow');
		}
	});
}


function removeVietnameseDiacritics(str) {
	var diacriticsMap = {
		á: "a",
		à: "a",
		ả: "a",
		ã: "a",
		ạ: "a",
		ắ: "a",
		ằ: "a",
		ẳ: "a",
		ẵ: "a",
		ặ: "a",
		ấ: "a",
		â: "a",
		ầ: "a",
		ẩ: "a",
		ẫ: "a",
		ậ: "a",
		é: "e",
		è: "e",
		ẻ: "e",
		ẽ: "e",
		ẹ: "e",
		ế: "e",
		ề: "e",
		ể: "e",
		ễ: "e",
		ệ: "e",
		í: "i",
		ì: "i",
		ỉ: "i",
		ĩ: "i",
		ị: "i",
		ó: "o",
		ò: "o",
		ỏ: "o",
		õ: "o",
		ọ: "o",
		ố: "o",
		ồ: "o",
		ổ: "o",
		ỗ: "o",
		ộ: "o",
		ớ: "o",
		ờ: "o",
		ở: "o",
		ỡ: "o",
		ợ: "o",
		ú: "u",
		ù: "u",
		ủ: "u",
		ũ: "u",
		ụ: "u",
		ư: "u",
		ứ: "u",
		ừ: "u",
		ử: "u",
		ữ: "u",
		ự: "u",
		ý: "y",
		ỳ: "y",
		ỷ: "y",
		ỹ: "y",
		ỵ: "y",
		đ: "d",
		ă: "a",
		Á: "A",
		À: "A",
		Ả: "A",
		Ã: "A",
		Ạ: "A",
		Ắ: "A",
		Ằ: "A",
		Ẳ: "A",
		Ẵ: "A",
		Ặ: "A",
		Ấ: "A",
		Â: "A",
		Ầ: "A",
		Ẩ: "A",
		Ẫ: "A",
		Ậ: "A",
		É: "E",
		È: "E",
		Ẻ: "E",
		Ẽ: "E",
		Ẹ: "E",
		Ế: "E",
		Ề: "E",
		Ể: "E",
		Ễ: "E",
		Ệ: "E",
		Í: "I",
		Ì: "I",
		Ỉ: "I",
		Ĩ: "I",
		Ị: "I",
		Ó: "O",
		Ò: "O",
		Ỏ: "O",
		Õ: "O",
		Ọ: "O",
		Ố: "O",
		Ồ: "O",
		Ổ: "O",
		Ỗ: "O",
		Ộ: "O",
		Ớ: "O",
		Ờ: "O",
		Ở: "O",
		Ỡ: "O",
		Ợ: "O",
		Ú: "U",
		Ù: "U",
		Ủ: "U",
		Ũ: "U",
		Ụ: "U",
		Ư: "U",
		Ứ: "U",
		Ừ: "U",
		Ử: "U",
		Ữ: "U",
		Ự: "U",
		Ý: "Y",
		Ỳ: "Y",
		Ỷ: "Y",
		Ỹ: "Y",
		Ỵ: "Y",
		Đ: "D",
		Ă: "A",
	};

	// Remove diacritics
	var withoutDiacritics = str.replace(/[^\u0000-\u007E]/g, function (a) {
		return diacriticsMap[a] || a;
	});

	// Replace spaces with hyphens and convert to lowercase
	return withoutDiacritics.replace(/ /g, "-").toLowerCase();
}
